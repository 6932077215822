<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <div class="row">
      <div class="col-12">
        <v-alert color="cyan" border="left" elevation="2" colored-border>
          <v-form ref="form" v-model="isFormValid" class="w-100">
            <div class="row">
              <div class="col-12">
                <v-text-field
                  v-model="name"
                  :counter="255"
                  :rules="name_rules"
                  label="İnvertör Adı"
                  required
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  v-model="inverter_id"
                  label="İnventör Id"
                  filled
                  readonly
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-select
                  v-model="selectedInverterType.inverter_type_id"
                  :items="inverterTypes"
                  item-value="inverter_type_id"
                  item-text="text"
                  label="İnvertör Tipi"
                  required
                  :rules="inverter_type_id_rules"
                ></v-select>
              </div>
              <div class="col-6">
                <v-select
                  v-model="selectedArea.station_field_id"
                  :items="areas"
                  item-value="station_field_id"
                  item-text="text"
                  label="Saha Adı"
                  required
                  :rules="station_field_id_rules"
                ></v-select>
              </div>
              <div class="col-6">
                <v-text-field
                  v-model="dataLogger"
                  label="Data Logger"
                  filled
                  readonly
                >
                </v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  v-model="connected_panel_count"
                  :rules="connected_panel_count_rules"
                  label="Bağlı Panel Sayısı"
                  required
                ></v-text-field>
              </div>
              <div class="col-6">
                <v-text-field
                  v-model="connected_panel_total_power"
                  :rules="connected_panel_total_power_rules"
                  label="Bağlı Panellerin Toplam Gücü"
                  type="number"
                  step="any"
                ></v-text-field>
              </div>
              <div class="col-12 text-center">
                <v-btn
                  :disabled="!isFormValid"
                  color="error"
                  class="mr-4 bg-success"
                  @click="update"
                >
                  Kaydet
                </v-btn>
              </div>
            </div>
          </v-form>
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import customBreadcrumb from "@/view/custom/breadcrumb";
import { GET_AREAS } from "@/core/services/store/areas.module";
import {
  UPDATE_INVERTER,
  GET_INVERTER,
} from "@/core/services/store/inverters.module";
import { GET_INVERTER_TYPES } from "@/core/services/store/inverterTypes.module";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "inverters.edit",
  components: {
    customBreadcrumb,
  },
  data() {
    return {
      //Data Logger
      dataLogger: "",
      //Area
      selectedArea: { station_field_id: 1, text: null },
      areas: [],
      selectedInverterType: { inverter_type_id: null, text: null },
      inverterTypes: [],
      //Breadcrumb
      customBreadcrumb: {
        title: "İnvertörler",
        type: "Düzenle",
        pathName: null,
      },
      // Form Field & Validations
      isFormValid: false,
      name: "",
      name_rules: [
        // v => !!v || "Boş bırakılamaz",
        // v => (v && v.length <= 255) || "Ad 255 karakterden az olmalıdır."
      ],
      inverter_type_id: "",
      inverter_type_id_rules: [(v) => !!v || "Boş bırakılamaz"],
      station_field_id: "",
      station_field_id_rules: [(v) => !!v || "Boş bırakılamaz"],
      connected_panel_count: "",
      connected_panel_count_rules: [
        // v => !!v || "Boş bırakılamaz",
      ],
      connected_panel_total_power: "",
      connected_panel_total_power_rules: [
        // v => (!isNaN(parseFloat(v))) || 'Sayı olmalıdır.',
      ],
    };
  },
  methods: {
    update() {
      let id = this.$route.params.id;
      let name = this.name;
      let inverter_type_id = this.selectedInverterType.inverter_type_id;
      let station_field_id = this.selectedArea.station_field_id;
      let connected_panel_count = this.connected_panel_count;
      let connected_panel_total_power = this.connected_panel_total_power;
      this.$store
        .dispatch(UPDATE_INVERTER, {
          id,
          name,
          inverter_type_id,
          station_field_id,
          connected_panel_count,
          connected_panel_total_power,
        })
        .then((res) => {
          this.$router.push({ name: "inverters" });
        });
    },
  },
  created() {
    this.$store.dispatch(GET_AREAS).then((res) => {
      res.forEach((el) => {
        let data = {
          station_field_id: el.id,
          text: el.power_station_name + " / " + el.name,
        };
        this.areas.push(data);
      });
    });
    this.$store.dispatch(GET_INVERTER_TYPES).then((res) => {
      res.forEach((el) => {
        let data = {
          inverter_type_id: el.id,
          text: el.brand + " - " + el.model,
        };
        this.inverterTypes.push(data);
      });
    });
    this.$store.dispatch(GET_INVERTER, this.$route.params.id).then((res) => {
      this.name = res.name;
      this.inverter_id = res.inverter_id;
      this.selectedInverterType = {
        inverter_type_id: res.inverter_type_id,
        text: null,
      };
      this.selectedArea = {
        station_field_id: res.station_field_id,
        text: null,
      };
      this.dataLogger = res.datalogger_id;
      this.connected_panel_count = res.connected_panel_count;
      this.connected_panel_total_power = res.connected_panel_total_power;
    });
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "İnvertörler", route: "/inverters", icon: "flaticon-dashboard" },
      { title: "Ekle" },
    ]);
  },
};
</script>

<style scoped>
</style>
